@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

[class*="panel__"] {
	.wpb_button, .wpb_content_element, ul.wpb_thumbnails-fluid > li {
		margin-bottom: 0;
	}
}

.panel {
	&__list {
		list-style: none;
		margin: 0;
		text-align: left;
	}

	&__head {
		text-align: left;
	}

	&__wrapper-gradient {
		.panel {
			&__head {
				padding: 30px 50px 18px;
				border-radius: 30px 30px 0 0;
				border-bottom: 3px solid var(--brk-secondary-3);
				box-shadow: 0 6px 30px 0 rgba($black, 0.12);
				position: relative;
				z-index: 1;

				.text-gray {
					color: #9F9F9F;
				}

				hr {
					border: none;
					width: calc(100% + 100px);
					height: 1px;
					position: relative;
					left: -50px;
					background: #ECECEC;
					margin: 28px 0 18px;
				}
			}

			&__list {
				background: $white;
				border-radius: 0 0 30px 30px;
				box-shadow: 0 6px 30px 0 rgba($black, 0.12);
				padding-bottom: 27px;

				.text-gray {
					color: #9F9F9F;
				}

				li {
					color: #9F9F9F;
					font-family: 'Open Sans', sans-serif;
					font-size: 14px;
					border-bottom: 1px solid #ECECEC;
					position: relative;
					z-index: 1;
					padding: 18px 18px 18px 50px;

					&:after {
						content: '';
						position: absolute;
						top: -6px;
						left: -15px;
						right: -15px;
						bottom: -6px;
						background: $white;
						box-shadow: 0 6px 30px 0 rgba($black, 0.12);
						opacity: 0;
						transition: all .3s ease;
						transform: scale(0.95);
						z-index: -1;
					}

					&:hover:after {
						transform: scale(1);
						opacity: 1;
					}
					&:hover .line {
						left: -15px;
					}
					&:nth-child(1) span {
						opacity: 0.16;
					}
					&:nth-child(2) span {
						opacity: 0.33;
					}
					&:nth-child(3) span {
						opacity: 0.5;
					}
					&:nth-child(4) span {
						opacity: 0.66;
					}
					&:nth-child(5) span {
						opacity: 0.83;
					}

					.line {
						position: absolute;
						top: 50%;
						transform: translate(0, -50%);
						transition: all .4s ease;
						left: 0;
						width: 3px;
						height: 36px;
						border-radius: 0 30px 30px 0;
						background: var(--brk-secondary-3);
					}
				}
			}
		}
	}

	&__wrapper-table {
		.panel {
			&__head {
				background: linear-gradient(to right, var(--brand-primary), var(--secondary));
				padding: 30px 18px;
				border-radius: 30px 30px 0 0;
				border-bottom: 3px solid var(--secondary);
				box-shadow: 0 6px 30px 0 rgba($black, 0.12);

				@media #{$sm-min} {
					padding: 30px 50px;
				}

				p {
					flex: 1;
					margin-top: 20px;
				}

				.text-gray {
					color: #B2ACEB;
				}

				@media(min-width: 992px) {
					display: flex;
					align-items: center;
					p {
						margin-top: 0;
						margin-left: 58px;
					}
				}
			}

			&__list {
				background: $white;
				border-radius: 0 0 30px 30px;
				box-shadow: 0 6px 30px 0 rgba($black, 0.12);
				padding-bottom: 27px;

				.text-gray {
					color: #B2ACEB;
				}

				li {
					color: #9F9F9F;
					font-family: 'Open Sans', sans-serif;
					font-size: 14px;
					background: $white;
					border-bottom: 1px solid #ECECEC;
					position: relative;
					z-index: 1;
					padding: 18px;

					@media #{$sm-min} {
						padding-left: 50px;
					}

					&:after {
						content: '';
						position: absolute;
						top: -6px;
						left: -15px;
						right: -15px;
						bottom: -6px;
						background: $white;
						box-shadow: 0 6px 30px 0 rgba($black, 0.12);
						opacity: 0;
						transition: all .3s ease;
						transform: scale(0.95);
						z-index: -1;
					}

					&:nth-child(2n), &:nth-child(2n):after {
						background: #F6F6F6;
					}
					&:hover {
						z-index: 2;
						&:after {
							transform: scale(1);
							opacity: 1;
						}
					}
				}
			}
		}
	}

	&__wrapper-icon {
		.panel {
			&__head {
				background: linear-gradient(to right, var(--brand-primary), var(--secondary));
				padding: 24px 40px;
				box-shadow: 0 6px 30px 0 rgba($black, 0.12);
				color: rgba(#fff, .9);

				p {
					flex: 1;
					margin-top: 20px;
				}

				@media(min-width: 768px) {
					padding: 24px 50px;
				}
				@media(min-width: 992px) {
					display: flex;
					align-items: center;
					p {
						margin-top: 0;
						margin-left: 58px;
					}
				}
			}

			&__list {
				background: $white;
				box-shadow: 0 6px 30px 0 rgba($black, 0.12);
				.text-gray {
					color: #B2ACEB;
				}

				li {
					color: #4e4e4e;
					font-family: 'Open Sans', sans-serif;
					font-size: 14px;
					border-bottom: 1px solid #ECECEC;
					position: relative;
					z-index: 1;
					padding: 28px;

					@media #{$sm-min}{
						padding-left: 110px;
					}

					&:nth-child(2n) {
						background: #F6F6F6;
					}

					&:hover {
						z-index: 2;

						&:after {
							transform: scale(1);
							opacity: 1;
						}

						.icon {
							opacity: 1;
						}

						.line {
							opacity: 1;
							left: -15px;
							transition-delay: 0.1s;
						}
					}

					&:after {
						content: '';
						position: absolute;
						top: -6px;
						left: -15px;
						right: -15px;
						bottom: -6px;
						background: $white;
						box-shadow: 0 6px 30px 0 rgba($black, 0.12);
						opacity: 0;
						transition: all .3s ease;
						transform: scale(0.95);
						z-index: -1;
					}

					.icon {
						font-size: 36px;
						color: var(--brand-primary);
						opacity: 0.5;
						transition: all .3s ease;
						position: relative;
						margin-bottom: 20px;
						width: auto !important;
						height: auto !important;
						line-height: normal !important;
						margin-left: 0 !important;

						@media #{$sm-min} {
							position: absolute;
							left: 40px;
							margin-bottom: 0;
							top: 50%;
							transform: translate(0, -50%);
						}

						@media(min-width: 992px) {
						}

					}
					.line {
						position: absolute;
						top: 50%;
						transform: translate(0, -50%);
						left: 0;
						width: 3px;
						height: 40px;
						background: linear-gradient(to bottom, var(--brand-primary), var(--secondary));
						opacity: 0;
						transition: all .3s ease;
					}
				}
			}
		}
	}

	&__wrapper-image {
		border-radius: 30px 30px 0 0;

		.panel {
			&__head {
				padding: 30px 50px;
				border-radius: 30px 30px 0 0;
				border-bottom: 3px solid var(--brk-secondary-3);
				box-shadow: 0 6px 30px 0 rgba($black, 0.12);
				p {
					flex: 1;
					margin-top: 20px;
				}
				.text-gray {
					color: #B2ACEB;
				}
				@media(min-width: 992px) {
					display: flex;
					align-items: center;
					p {
						margin-top: 0;
						margin-left: 58px;
					}
				}

				.overlay_after {
					border-radius: 30px 30px 0 0;
				}
			}

			&__list {
				background: $white;
				border-radius: 30px;
				box-shadow: 0 6px 30px 0 rgba($black, 0.12);
				counter-reset: li;

				.text-gray {
					color: #B2ACEB;
				}

				li {
					color: #9F9F9F;
					font-family: 'Open Sans', sans-serif;
					font-size: 14px;
					background: $white;
					border-bottom: 1px solid #ECECEC;
					position: relative;
					padding: 28px;
					padding-left: 110px;

					&:last-child {
						border-bottom: none;
					}
					&:nth-child(2n) {
						background: #F6F6F6;
					}
					&:hover {
						.count {
							color: $white;
							background: linear-gradient(50deg, var(--brk-base-3), var(--secondary));
						}
					}

					.count {
						display: flex;
						justify-content: center;
						align-items: center;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						width: 90px;
						color: rgba(var(--brand-primary-rgb), .3);
						font-family: 'Montserrat', sans-serif;
						font-size: 36px;
						border-right: 1px solid #ECECEC;
						background: #FBFBFB;
						// &:before{
						//  	content: "0" counter(li);
						//  	counter-increment: li;
						//  }
					}
				}
			}
		}

		.overlay_after {
			border-radius: 0 0 25px 25px;
		}

		.item-footer {
			height: 27px;
			border-radius: 0 0 30px 30px;
			position: relative;
			z-index: -1;
			box-shadow: 0 6px 30px 0 rgba($black, 0.12);
		}
	}

	&__wrapper-large {
		.panel {
			&__head {
				text-align: center;
				background: linear-gradient(to right, var(--brk-base-3), var(--secondary));
				padding: 31px 50px;
				box-shadow: 0 6px 30px 0 rgba($black, 0.12);

				@media(min-width: 992px) {
					padding: 31px 70px;
				}

				.text-gray {
					color: #B2ACEB;
				}

				hr {
					border: none;
					width: 100%;
					height: 1px;
					background: $white;
					opacity: 0.2;
					margin: 24px 0 20px;
				}
			}

			&__list {
				text-align: left;
				background: $white;
				box-shadow: 0 6px 30px 0 rgba($black, 0.12);
				border-bottom: 3px solid var(--brand-primary);

				.text-gray {
					color: #B2ACEB;
				}

				li {
					color: #9F9F9F;
					font-family: 'Open Sans', sans-serif;
					font-size: 14px;
					border-bottom: 1px solid #ECECEC;
					border-left: 3px solid #E4E4E4;
					position: relative;
					z-index: 1;
					padding: 38px;

					@media #{$sm-min} {
						padding-left: 110px;
					}

					&:last-child {
						border-bottom: none;
					}

					&:nth-child(2n) {
						background: #F6F6F6;
					}

					&:hover {
						border-left-color: var(--brand-primary);

						.icon {
							opacity: 1;
						}

						.line {
							transform: rotateY(0deg);
						}
					}

					.icon {
						font-size: 36px;
						color: var(--brand-primary);
						opacity: 0.4;
						transition: all .3s ease;
						position: relative;
						top: auto;
						left: auto;
						margin-bottom: 20px;

						@media(min-width: 768px) {
							position: absolute;
							left: 40px;
							margin-bottom: 0;
							top: 50%;
							transform: translate(0, -50%);
						}

						@media(min-width: 992px) {
						}
					}

					.line {
						position: absolute;
						top: 0;
						left: 0;
						width: 2px;
						height: 100%;
						background: var(--brand-primary);
						transition: all .3s ease;
						transform: rotateY(90deg);
						.before {
							position: absolute;
							left: 100%;
							top: 50%;
							transform: translate(0, -50%);
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 8px 0 8px 8px;
							border-color: transparent transparent transparent var(--brand-primary);
						}
					}
				}
			}
		}
	}

	&__wrapper-info-block {
		.panel {
			&__head {
				text-align: center;
				background: linear-gradient(to right, var(--brk-base-3), var(--secondary));
				padding: 31px 50px;
				box-shadow: 0 6px 30px 0 rgba($black, 0.12);

				@media(min-width: 992px) {
					padding: 31px 70px;
				}

				.text-gray {
					color: #9F9F9F;
				}
			}

			&__list {
				padding: 70px 30px;
				background: $white;
				border-bottom: 3px solid var(--brand-primary);
				box-shadow: 0 6px 30px 0 rgba($black, 0.12);
				position: relative;
				z-index: 1;

				@media(min-width: 768px) {
					padding: 70px;
				}

				i.left-icon {
					font-size: 36px;
					color: var(--brand-primary);

					@media #{$sm-min} {
						float: left;
						margin-right: -100px;
						margin-top: 10px;
					}
				}

				.list_wrapper {
					@media #{$sm-min} {
						padding-left: 100px;
					}
				}

				.text-gray {
					color: #9F9F9F;
				}

				.highlight-underline {
					margin-top: 48px;

					& + .highlight-underline {
						margin-top: -2px;
					}
				}

				p {
					margin-top: 37px;
				}

				.cont {
					text-align: left;
					padding-left: 60px;
					@media(min-width: 768px) {
						padding-left: 100px;
					}
				}

				.panel__icon-abs {
					font-size: 36px;
					color: var(--brand-primary);
					position: absolute;
					top: 70px;
					left: 25px;
					@media(min-width: 768px) {
						left: 70px;
					}
				}

				.heading-style-left div + div .highlight-underline,
				.heading-style-left h4 + h4.highlight-underline {
					margin-top: -2px;
				}
			}
		}
	}
}

// color
.panel {
	&__wrapper-large.brk-color,
	&__wrapper-info-block.brk-color {
		.panel {
			&__head {
				background: linear-gradient(to right, var(--brk-base-3), var(--secondary));
			}
		}
	}

	&__wrapper-image.brk-color {
		.panel {
			&__head {
				border-bottom: 3px solid var(--brk-secondary-3);
			}
		}
	}

	&__wrapper-icon.brk-color {
		.panel {
			&__head {
				background: linear-gradient(to right, var(--brk-base-3), var(--secondary));
			}
		}
	}

	&__wrapper-table.brk-color {
		.panel {
			&__head {
				background: linear-gradient(to right, var(--brk-base-3), var(--secondary));
			}
		}
	}

	&__wrapper-gradient.brk-color {
		.panel {
			&__head {
				border-bottom: 3px solid var(--brk-secondary-3);
			}
		}
	}
}